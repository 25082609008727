export default () => {
  if (document.querySelector('.user script')) return

  const container = document.querySelector('.tg-auth-container')

  if (!container) return

  const script = document.createElement('script')
  script.async = true
  script.src = 'https://telegram.org/js/telegram-widget.js?22'
  script.dataset.telegramLogin = process.env.NEXT_PUBLIC_TELEGRAM_BOT_NAME
  script.dataset.size = 'medium'
  script.dataset.userpic = 'false'
  script.dataset.radius = '14'
  script.dataset.requestAccess = 'write'

  container.appendChild(script)
}
