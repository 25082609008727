import Link from 'next/link'
import styles from './NavItem.module.scss'
import { FC, MouseEvent, useState } from 'react'
import ArrowSvg from '../../public/icons/arrow-nav.svg'
import DropdownMenu from './DropdownMenu'
import cn from 'classnames'
import StarsSvg from '../../public/icons/stars.svg'
import { INavItem } from '../../types'

interface NavItemProps {
  item: INavItem
  hasCount: boolean
  mobile?: boolean
  footer?: boolean
  isLast?: boolean
  handlerClick: (e: MouseEvent<HTMLAnchorElement>, link: string) => void
}

const NavItem: FC<NavItemProps> = ({
  item,
  hasCount,
  mobile,
  footer,
  isLast,
  handlerClick,
}) => {
  const [isDropdownVisible, setIsDropdownVisible] = useState(false)
  let timeout = null

  const renderNavItemContent = () => (
    <>
      <span className={styles.name}>{item.name}</span>
      {hasCount && !footer && <sup className={styles.total}>{item.total}</sup>}
      {item.subcat && !mobile && !footer && (
        <ArrowSvg className={styles.arrow} />
      )}
      {item.slug === 'ai' && <StarsSvg />}
    </>
  )

  return (
    <div
      className={cn(
        styles.container,
        mobile && styles.mobile,
        footer && styles.footer
      )}
      onMouseEnter={() => {
        clearTimeout(timeout)
        setIsDropdownVisible(true)
      }}
      onMouseLeave={() => {
        clearTimeout(timeout)
        timeout = setTimeout(() => setIsDropdownVisible(false), 300)
      }}
    >
      {item.href ? (
        <Link
          href={item.href}
          className={cn(styles.item, item.slug === 'ai' && styles.specialTag)}
          key={item.name}
          onClick={(e) => handlerClick(e, item.href)}
        >
          {renderNavItemContent()}
        </Link>
      ) : (
        <div className={styles.item} key={item.name}>
          {renderNavItemContent()}
        </div>
      )}
      {isDropdownVisible && item.subcat && !mobile && !footer && (
        <DropdownMenu subItems={item.subcat} isLast={isLast} />
      )}
    </div>
  )
}

export default NavItem
