import NextImage, { ImageProps } from 'next/image'

const Image: React.FC<
  ImageProps & { withOptimize?: boolean; skipSizes?: boolean }
> = ({ ...props }) => {
  const { withOptimize, skipSizes, quality = 75, ...originalProps } = props

  return (
    <NextImage
      sizes={skipSizes ? null : `${props.width}px`}
      loader={
        props.withOptimize && props.width
          ? ({ src, width }) =>
              `${
                process.env.NEXT_PUBLIC_IMAGE_OPTIM
              }/rs:fill/w:${width}/q:${quality}/plain/${encodeURIComponent(
                src
              )}@webp`
          : null
      }
      quality={quality}
      {...originalProps}
    />
  )
}

export default Image
