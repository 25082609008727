import 'swiper/css'
import 'swiper/css/effect-fade'
import '../styles/normalize.css'
import '../styles/global.scss'

import type { AppProps } from 'next/app'
import PlausibleProvider from 'next-plausible'
import { ApolloProvider } from '@apollo/client'

import Layout from '../features/layout/Layout'
import { useEffect } from 'react'
import { useRouter } from 'next/router'
import { useApollo } from '../utils/apollo'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import AuthProvider from '../contexts/AuthContext'
import useSessionStorage from '../hooks/useSessionStorage'
import LayoutProvider from '../contexts/LayoutContext'

export default function MyApp({
  Component,
  pageProps: { fallback, footerMargin, isWhite, ...pageProps },
}: AppProps<{
  footerMargin: boolean
  fallback: any
  isWhite?: boolean
}>) {
  const router = useRouter()
  const [, setPrevPath] = useSessionStorage('prevPath', '')
  const apolloClient = useApollo((pageProps as any).initialApolloState)
  let currentRoute = router.pathname

  useEffect(() => {
    const handleRouteChange = () => {
      setPrevPath(currentRoute)
    }
    router.events.on('routeChangeStart', handleRouteChange)
    return () => {
      router.events.off('routeChangeStart', handleRouteChange)
    }
  }, [])

  useEffect(() => {
    router.beforePopState(({ as }) => {
      if (as !== router.asPath) {
        document.documentElement.classList.remove('fslightbox-open')
      }
      return true
    })

    return () => {
      router.beforePopState(() => true)
    }
  }, [router])

  return (
    <ApolloProvider client={apolloClient}>
      <GoogleReCaptchaProvider
        reCaptchaKey={process.env.NEXT_PUBLIC_RECAPTCHA_KEY}
      >
        <PlausibleProvider
          customDomain="https://stat.kod.ru"
          domain={process.env.NEXT_PUBLIC_HOST.split('//')[1]}
          selfHosted
          enabled
        >
          <AuthProvider>
            <LayoutProvider>
              <Layout
                meta={(pageProps as any).meta}
                footerMargin={footerMargin}
                rssLink={(pageProps as any).rssLink}
                isWhite={isWhite}
                tgUsersCounter={(pageProps as any).tgUsersCounter}
              >
                <Component {...pageProps} />
              </Layout>
            </LayoutProvider>
          </AuthProvider>
        </PlausibleProvider>
      </GoogleReCaptchaProvider>
    </ApolloProvider>
  )
}
